import { CustomTargetApi, TvDataSearchApi } from '@/api';
import { TvDataSearchProgram } from '@/api/openapi';
import { TARGET_NAME, httpCode } from '@/common/constant';
import { DATE_FORMAT } from '@/common/format';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { useDuration, DataType } from '@/composables/duration';
import { ROUTE_NAMES } from '@/router';
import { useAreaOptions } from '@/store/areaOptions';
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import { Ref, computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { DateRange } from '@/components/ui/DatePicker.vue';

import { CustomTarget, SelectableAreasList } from '@/api/openapi';

type ProgramType =
  | 'REGULAR'
  | 'SPECIAL'
  | 'ONE_OFF'
  | 'REBROADCASTING'
  | 'PROMOTION'
  | 'UNKNOWN';
export type ProgramGenre =
  | 'NEWS'
  | 'WIDESHOW'
  | 'MUSIC'
  | 'VARIETY'
  | 'DRAMA'
  | 'ANIME'
  | 'CINEMA'
  | 'SPORTS'
  | 'DOCUMENTARY'
  | 'EDUCATION'
  | 'THEATRICAL_PLAY'
  | 'WELFARE'
  | 'OTHER';
type DayOfWeek =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';
export type DataDivision = 'VIEWING_RATE' | 'CONTENT_RATE';
export type AggregationUnit = 'BROADCAST_TIMES' | 'PROGRAM_TIMES';

interface ProgramTypeOption {
  label: string;
  id: ProgramType;
}
interface ProgramGenreOption {
  label: string;
  id: ProgramGenre;
}
interface DayOfWeekOption {
  label: string;
  id: DayOfWeek;
  sort: number;
}
interface DataDivisionOption {
  label: string;
  id: DataDivision;
}
interface AggregationUnitOption {
  label: string;
  id: AggregationUnit;
}
interface CheckBoxGroup {
  label: string;
  id: string;
}
interface RadioButtonGroup {
  label: string;
  value: string;
}

export const PROGRAM_TYPES: Array<ProgramTypeOption> = [
  { label: 'レギュラー', id: 'REGULAR' },
  { label: 'スペシャル', id: 'SPECIAL' },
  { label: '単発', id: 'ONE_OFF' },
  { label: '再放送', id: 'REBROADCASTING' },
  { label: '番宣', id: 'PROMOTION' }
];
export const PROGRAM_GENRES: Array<ProgramGenreOption> = [
  { label: 'ニュース・報道', id: 'NEWS' },
  { label: '情報・ワイドショー', id: 'WIDESHOW' },
  { label: '音楽', id: 'MUSIC' },
  { label: 'バラエティ', id: 'VARIETY' },
  { label: 'ドラマ', id: 'DRAMA' },
  { label: 'アニメ・特撮', id: 'ANIME' },
  { label: '映画', id: 'CINEMA' },
  { label: 'スポーツ', id: 'SPORTS' },
  { label: 'ドキュメンタリー', id: 'DOCUMENTARY' },
  { label: '趣味・教育', id: 'EDUCATION' },
  { label: '演劇・公演', id: 'THEATRICAL_PLAY' },
  { label: '福祉', id: 'WELFARE' },
  { label: 'その他', id: 'OTHER' }
];
export const DAY_OF_WEEKS: Array<DayOfWeekOption> = [
  { label: '月', id: 'MONDAY', sort: 1 },
  { label: '火', id: 'TUESDAY', sort: 2 },
  { label: '水', id: 'WEDNESDAY', sort: 3 },
  { label: '木', id: 'THURSDAY', sort: 4 },
  { label: '金', id: 'FRIDAY', sort: 5 },
  { label: '土', id: 'SATURDAY', sort: 6 },
  { label: '日', id: 'SUNDAY', sort: 7 }
];
export const DATA_DIVISIONS: Array<DataDivisionOption> = [
  { label: '視聴率', id: 'VIEWING_RATE' },
  { label: '含有率（個人）', id: 'CONTENT_RATE' }
];
export const AGGREGATION_UNITS: Array<AggregationUnitOption> = [
  { label: '放送回ごと', id: 'BROADCAST_TIMES' },
  { label: '番組ごと', id: 'PROGRAM_TIMES' }
];

export interface FormValue {
  selectDate: Ref<DateRange | undefined>;
  startTime: Ref<{ HH: string; mm: string }>;
  endTime: Ref<{ HH: string; mm: string }>;
  area: Ref<string>;
  areaOptions: Ref<Array<SelectableAreasList>>;
  programTypes: Ref<Array<ProgramType>>;
  min15Type: Ref<boolean>;
  programSeriesIds: Ref<Array<number>>;
  programNames: Ref<Array<string>>;
  targetName: Ref<string>;
  isCustomTarget: Ref<boolean>;
  basicTargetIds: Ref<Array<number>>;
  customTargetId: Ref<Array<number>>;
  aggregationUnit: Ref<AggregationUnit>;
  dataDivisions: Ref<Array<DataDivision>>;
  stations: Ref<Array<string>>;
  stationOptions: Ref<Array<CheckBoxGroup>>;
  dayOfWeeks: Ref<Array<DayOfWeek>>;
  holidayType: Ref<boolean>;
  programGenres: Ref<Array<ProgramGenre>>;
  selectStationValues: Ref<Array<string>>;
}

interface useProgramListFormBoxType {
  startTimeValue: Ref<{ HH: string; mm: string }>;
  endTimeValue: Ref<{ HH: string; mm: string }>;
  minDate: Ref<Date>;
  maxDate: Ref<Date>;
  calendarId: Ref<string>;
  isLoadingDuration: Ref<boolean>;
  isAreaValueTimeout: Ref<boolean>;
  isLoadingOnChangeArea: Ref<boolean>;
  areaValue: Ref<string>;
  tvStations: Ref<CheckBoxGroup[]>;
  stationsValue: Ref<string[]>;
  stationsValid: Ref<string>;
  tvProgramsValue: Ref<{ programSeriesId: number; name: string }[]>;
  programNames: Ref<[] | undefined>;
  targetName: Ref<string>;
  targetValid: Ref<string>;
  targetContentRateValid: Ref<string>;
  durationRef: Ref<DateRange | undefined>;
  durationValid: Ref<string>;
  selectHour: number[];
  convertTimeValid: Ref<string | undefined>;
  isLoading: Ref<boolean>;
  isDisabled: Ref<boolean>;
  isSubMenuOpen: Ref<boolean>;
  selectOptions: Ref<SelectableAreasList[]>;
  customTargetList: Ref<
    { id: number; label: string; isEnabled?: boolean | undefined }[]
  >;
  isCustomTargetTimeout: Ref<boolean>;
  fetchPrograms: (search: string) => Promise<Array<TvDataSearchProgram>>;
  onChangeTarget: (target: { name: string; value: { id: string } }) => void;
  programTypes: CheckBoxGroup[];
  programTypesValue: Ref<string[]>;
  programTypesValid: Ref<string>;
  aggregationUnits: RadioButtonGroup[];
  aggregationUnitValue: Ref<string>;
  dataDivisions: CheckBoxGroup[];
  dataDivisionsValue: Ref<string[]>;
  dataDivisionsValid: Ref<string>;
  min15TypeValue: Ref<boolean>;
  programGenres: CheckBoxGroup[];
  programGenresValue: Ref<string[]>;
  programGenresIsAllClearValue: Ref<boolean>;
  programGenresOnAllClear: (v: boolean) => void;
  programGenresOnSelect: () => void;
  dayTypes: CheckBoxGroup[];
  dayTypesValue: Ref<string[]>;
  dayTypesValid: Ref<string>;
  holidayTypesValue: Ref<boolean>;
  form: FormValue;
}

export const useProgramListFormBox = (): useProgramListFormBoxType => {
  const areaOptionStore = useAreaOptions();
  const route = useRoute();
  // NOTE:カスタムターゲットが権限チェックに利用されるので親に持たせる
  const customTargets = ref<CustomTarget[]>([]);

  const { params } = route;
  const companyId = ref(Number(params.companyId));
  const isDisabled = ref(false);
  const isSubMenuOpen = ref(false);

  // 検索周り
  const programSearchString = '';

  // 時間帯
  const {
    selectHour,
    startTimeValue,
    endTimeValue,
    convertStartTimeValue,
    convertEndTimeValue,
    convertTimeValid
  } = useTime();
  // 放送
  const {
    tvStations,
    stationsValue,
    stationsValid,
    allStationCodes,
    onChangeTvStations
  } = useTvStations(companyId);

  // エリア
  const {
    areaValue,
    isLoading,
    selectOptions,
    onChangeArea,
    isAreaValueTimeout,
    isLoadingOnChangeArea
  } = useArea(areaOptionStore, onChangeTvStations);

  // 期間
  const {
    durationRef,
    durationValid,
    minDate,
    maxDate,
    calendarId,
    isLoadingDuration,
    initDuration
  } = useDuration(areaValue, DataType.PROGRAM);

  // データ区分
  const {
    dataDivisions,
    dataDivisionsValue,
    dataDivisionsValid
  } = useDataDivision();
  // ターゲット
  const {
    targetName,
    isCustomTarget,
    basicTargetIds,
    customTargetId,
    customTargetList,
    onChangeTarget,
    targetValid,
    targetContentRateValid,
    isCustomTargetTimeout
  } = useTargets(dataDivisionsValue, customTargets);

  // 番組タイプ
  const {
    programTypes,
    programTypesValue,
    programTypesValid
  } = useProgramType();
  // 集計単位
  const { aggregationUnits, aggregationUnitValue } = useAggregationUnit();

  // 15分未満の番組
  const { min15TypeValue } = useMin15Types();
  // 番組ジャンル
  const {
    programGenres,
    programGenresValue,
    programGenresIsAllClearValue,
    programGenresOnAllClear,
    programGenresOnSelect
  } = useProgramGenre();

  // 曜日
  const { dayTypes, dayTypesValue, dayTypesValid } = useDay();
  // 祝日
  const { holidayTypesValue } = useHoliday();

  // 番組名
  const {
    tvProgramsValue,
    programSeriesIds,
    programNames,
    fetchPrograms
  } = useTvPrograms(
    programSearchString,
    stationsValue,
    durationRef,
    startTimeValue,
    endTimeValue,
    dayTypesValue,
    programGenresValue,
    programTypesValue,
    holidayTypesValue,
    min15TypeValue,
    companyId,
    convertStartTimeValue,
    convertEndTimeValue,
    allStationCodes
  );

  created(
    areaValue,
    isAreaValueTimeout,
    areaOptionStore,
    route,
    onChangeArea,
    initDuration,
    companyId,
    customTargets,
    isCustomTargetTimeout
  );
  //エリア変更
  watch(areaValue, async () => {
    await onChangeArea(undefined);
  });

  // バリデーション
  const valids = ref({
    selectDate: false, //初期値がある
    programsType: false, //初期値がある
    target: false, //初期値がある
    targetContentRate: false, //初期値がある
    stations: false, //初期値がある
    dayOfWeekTypes: false, //初期値がある
    selectTime: false, //初期値がある
    dataDivisions: false //
  });
  watch(programTypesValue, () => {
    if (programTypesValue.value.length === 0) {
      valids.value.programsType = true;
    } else {
      valids.value.programsType = false;
    }
  }); // 番組タイプバリデーション
  watch([dataDivisionsValue, targetName], () => {
    if (
      (dataDivisionsValue.value.includes('CONTENT_RATE') &&
        targetName.value === TARGET_NAME.individual) ||
      dataDivisionsValue.value.length === 0
    ) {
      valids.value.dataDivisions = true;
    } else {
      valids.value.dataDivisions = false;
    }
  }); // データ区分で含有率を選択されてる場合はターゲットを個人全体以外
  watch(targetValid, () => {
    if (targetValid.value) {
      valids.value.target = true;
    } else {
      valids.value.target = false;
    }
  }); // ターゲットバリデーション
  watch(targetContentRateValid, () => {
    if (targetContentRateValid.value) {
      valids.value.targetContentRate = true;
    } else {
      valids.value.targetContentRate = false;
    }
  }); // ターゲットバリデーション
  watch(stationsValue, () => {
    if (stationsValue.value.length === 0) {
      valids.value.stations = true;
    } else {
      valids.value.stations = false;
    }
  }); // 放送局バリデーション
  watch(dayTypesValue, () => {
    if (dayTypesValue.value.length === 0) {
      valids.value.dayOfWeekTypes = true;
    } else {
      valids.value.dayOfWeekTypes = false;
    }
  }); // 時間帯バリデーション
  watch([startTimeValue, endTimeValue], () => {
    valids.value.selectTime = convertTimeValid.value != '';
  }); // 時間バリデーション
  // この表記意味がわからないけど、既存に併せて追加
  watch(durationValid, dataValidation => {
    valids.value.selectDate = dataValidation.length > 0;
  });
  watch(
    valids,
    v => {
      isDisabled.value = Object.values(v).includes(true);
    },
    { deep: true }
  ); //バリデーションまとめ
  const form = {
    selectDate: durationRef, // 期間：開始−終了
    startTime: startTimeValue, // 時間帯: 開始
    endTime: endTimeValue, // 時間帯: 終了
    area: areaValue, // エリア
    areaOptions: selectOptions, // エリアオプション
    programTypes: programTypesValue, // 番組タイプ
    min15Type: min15TypeValue, // 15分未満の番組
    programSeriesIds: programSeriesIds, // 番組シリーズIDのリスト
    programNames: programNames, // 番組名のリスト
    targetName: targetName, // ターゲット
    isCustomTarget: isCustomTarget, // カスタムターゲット有無
    basicTargetIds: basicTargetIds, // 基本属性ターゲットIDのリスト
    customTargetId: customTargetId, // カスタムターゲットID
    aggregationUnit: aggregationUnitValue, // 集計単位
    dataDivisions: dataDivisionsValue, // データ区分
    stations: allStationCodes, // 放送局
    stationOptions: tvStations, // 放送局オプション
    dayOfWeeks: dayTypesValue, // 曜日
    holidayType: holidayTypesValue, // 祝日
    programGenres: programGenresValue, // ジャンル
    selectStationValues: stationsValue // 放送局のチェックボックス選択状態
  };

  return {
    durationRef,
    durationValid,
    selectHour,
    convertTimeValid,
    startTimeValue,
    endTimeValue,
    minDate,
    maxDate,
    calendarId,
    isLoadingDuration,
    isAreaValueTimeout,
    isLoadingOnChangeArea,
    areaValue,
    isLoading,
    isDisabled,
    isSubMenuOpen,
    selectOptions,
    tvStations,
    stationsValue,
    stationsValid,
    tvProgramsValue,
    programNames,
    fetchPrograms,
    onChangeTarget,
    targetName,
    targetValid,
    targetContentRateValid,
    customTargetList,
    isCustomTargetTimeout,
    programTypes,
    programTypesValue,
    programTypesValid,
    programGenresIsAllClearValue,
    programGenresOnAllClear,
    programGenresOnSelect,
    aggregationUnits,
    aggregationUnitValue,
    dataDivisions,
    dataDivisionsValue,
    dataDivisionsValid,
    min15TypeValue,
    programGenres,
    programGenresValue,
    dayTypes,
    dayTypesValue,
    dayTypesValid,
    holidayTypesValue,
    form
  };
};
// init
const created = async (
  areaValue,
  isAreaValueTimeout,
  areaOptionStore,
  route,
  onChangeArea,
  onChangeInitDate,
  companyId,
  customTargets,
  isCustomTargetTimeout
) => {
  const router = useRouter();
  try {
    // エリア取得
    areaValue.value = await areaOptionStore.fetch(route);
    await onChangeArea();
    await onChangeInitDate(areaValue.value);
    isAreaValueTimeout.value = false;
  } catch (e) {
    if (e.state !== httpCode.forbidden) {
      toast({ title: 'エリア取得失敗', message: e.message, variant: 'error' });
    }
    isAreaValueTimeout.value = true;
  }
  try {
    // カスタムターゲット
    const getcustomTargets = await CustomTargetApi.getCompaniesCompanyIdCustomTargets(
      companyId.value
    );
    customTargets.value = getcustomTargets.data.list;
    isCustomTargetTimeout.value = false;
  } catch (e) {
    if (e.status !== httpCode.forbidden) {
      toast({
        title: 'カスタムターゲット取得失敗',
        message: e.message,
        variant: 'error'
      });
      isCustomTargetTimeout.value = true;
    } else {
      // 権限がない場合(カスタムターゲットが取得出来ない場合)
      router.push({
        name: ROUTE_NAMES.error,
        params: e
      });
    }
  }
};
// 時間帯
const useTime = () => {
  const selectHour = [
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28
  ];
  const startTimeValue = ref({
    HH: '05',
    mm: '00'
  }); //model
  const endTimeValue = ref({
    HH: '28',
    mm: '59'
  }); //model
  const convertStartTimeValue = computed(() => {
    return Object.values(startTimeValue.value).join(':');
  });
  const convertEndTimeValue = computed(() => {
    return Object.values(endTimeValue.value).join(':');
  });
  const convertTimeValid = computed(() => {
    let result: string;
    if (
      startTimeValue.value.HH &&
      startTimeValue.value.mm &&
      endTimeValue.value.HH &&
      endTimeValue.value.mm
    ) {
      if (convertStartTimeValue.value >= convertEndTimeValue.value) {
        result = '終了時間は開始時間より後にしてください。';
      } else {
        result = '';
      }
    } else {
      result = '時刻を入力してください。';
    }
    return result;
  });

  return {
    selectHour,
    startTimeValue,
    endTimeValue,
    convertStartTimeValue,
    convertEndTimeValue,
    convertTimeValid
  };
};
// エリア
const useArea = (areaOptionStore, onChangeTvStations) => {
  const areaValue = ref(); // model
  const isAreaValueTimeout = ref(false); // model

  const { isLoading, selectOptions } = storeToRefs(areaOptionStore);
  const _onChangeArea = async () => {
    await onChangeTvStations(areaValue);
  };
  const [isLoadingOnChangeArea, onChangeArea] = useLoading(_onChangeArea);

  return {
    areaValue,
    isAreaValueTimeout,
    isLoading,
    selectOptions,
    isLoadingOnChangeArea,
    onChangeArea
  };
};
// 番組名
const useTvPrograms = (
  programSearchString,
  stationsValue,
  duration,
  startTimeValue,
  endTimeValue,
  dayTypesValue,
  programGenresValue,
  programTypesValue,
  holidayTypeValue,
  min15TypeValue,
  companyId,
  convertStartTimeValue,
  convertEndTimeValue,
  allStationCodes
) => {
  const tvProgramsValue = ref(); // model
  const programSeriesIds = computed(() => {
    if (tvProgramsValue.value) {
      return tvProgramsValue.value.map(val => val.programSeriesId);
    } else {
      return [];
    }
  });
  const programNames = computed(() => {
    if (tvProgramsValue.value) {
      return tvProgramsValue.value.map(val => val.name);
    } else {
      return [];
    }
  });
  const fetchPrograms = async (search: string) => {
    if (
      stationsValue.value.length &&
      duration.value &&
      startTimeValue.value &&
      endTimeValue.value &&
      companyId.value
    ) {
      const programTypesWithUnknown = programTypesValue.value.concat([
        'UNKNOWN'
      ]);
      try {
        // 番組名取得
        const getTvPrograms = await TvDataSearchApi.getTvdataSearchProgramListProgramsSearch(
          allStationCodes.value,
          format(duration.value.start, DATE_FORMAT),
          format(duration.value.end, DATE_FORMAT),
          convertStartTimeValue.value,
          convertEndTimeValue.value,
          dayTypesValue.value,
          programGenresValue.value.length === 0
            ? PROGRAM_GENRES.map(v => v.id)
            : programGenresValue.value,
          programTypesWithUnknown,
          holidayTypeValue.value,
          !min15TypeValue.value,
          companyId.value,
          programSeriesIds.value,
          search
        );
        return getTvPrograms.data;
      } catch (e) {
        toast({
          title: '番組名取得失敗',
          message: e.message,
          variant: 'error'
        });
        return [];
      }
    }
    return [];
  };
  return {
    tvProgramsValue,
    programSeriesIds,
    programNames,
    fetchPrograms
  };
};
// 放送
const useTvStations = companyId => {
  const tvStations = ref<Array<CheckBoxGroup>>([]);
  const stationsValue = ref<Array<string>>([]); // model
  const allStationCodes = computed<Array<string>>(
    (): Array<string> => {
      const result: Array<string> = [];
      stationsValue.value.map(v => {
        result.push(...v.split(','));
      });
      return result;
    }
  ); // 系列局の中身をすべて保存する値

  const onChangeTvStations = async areaValue => {
    const stations = await TvDataSearchApi.getTvdataSearchStations(
      areaValue.value,
      companyId.value,
      false
    );
    tvStations.value = [...stations.data].map(v => ({
      id: v.stationCodes.join(','),
      label: v.displayStationName
    }));
    // 特定の放送局を列の後ろに回す処理
    const targets = ['NHK', 'ETV'];
    const [pass, deny] = tvStations.value.reduce(
      (a: CheckBoxGroup[][], c) => (
        a[1 - (targets.includes(c.id) ? 0 : 1)].push(c), a
      ),
      [[], []]
    );
    tvStations.value = pass.concat(deny);
    // 特定の放送局のcheckを外す
    stationsValue.value = tvStations.value
      .map(v => (!targets.includes(v.id) ? v.id : undefined))
      .filter(e => typeof e !== 'undefined') as string[];
  };
  const stationsValid = computed(() => {
    if (stationsValue.value.length === 0) {
      return 'いずれか一つは選択してください。';
    }
    return '';
  }); // valid

  return {
    tvStations,
    stationsValue,
    stationsValid,
    allStationCodes,
    onChangeTvStations
  };
};
// ターゲット
const useTargets = (dataDivisionsValue, customTargets) => {
  // カスタムターゲットの有無
  const targetName = ref('個人全体・世帯');
  const isCustomTarget = ref(false);
  const isCustomTargetTimeout = ref(false);
  const basicTargetIds = ref<number[]>([]);
  const customTargetId = ref<number[]>([]);
  const targetValid = ref<string>('');
  const customTargetList = computed(() => {
    return customTargets.value.map(({ id, name, isEnabled }) => ({
      id,
      label: name,
      isEnabled
    }));
  });
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeTarget = (target: { name: string; value: any }) => {
    targetName.value = target.name;
    // 個人全体・世帯の場合
    if (targetName.value === TARGET_NAME.individualAndHousehold) {
      isCustomTarget.value = false;
      basicTargetIds.value = [];
      customTargetId.value = [];
      targetValid.value = '';
      return;
    }
    // カスタムターゲットの場合
    if (targetName.value === TARGET_NAME.customTarget) {
      isCustomTarget.value = true;
      if (Array.isArray(target.value)) {
        if (target.value.length > 0) {
          customTargetId.value = target.value.map(v =>
            v instanceof Object ? v.id : v
          );
        } else {
          customTargetId.value = [];
        }
      } else if (target.value) customTargetId.value = target.value.id;
      targetValid.value =
        customTargetId.value.length === 0
          ? 'いずれか一つは選択してください。'
          : '';
      return;
    }
    // 基本属性ターゲットの場合
    isCustomTarget.value = false;
    if (target.value) basicTargetIds.value = target.value;
    targetValid.value =
      basicTargetIds.value.length === 0
        ? 'いずれか一つは選択してください。'
        : '';
  };
  const targetContentRateValid = computed(() => {
    // 個人全体・世帯の場合
    if (
      targetName.value === TARGET_NAME.individualAndHousehold &&
      dataDivisionsValue.value.includes('CONTENT_RATE')
    ) {
      return '含有率を指定する場合は、ターゲットを個人全体・世帯以外にしてください。';
    }
    return '';
  });
  return {
    targetName,
    customTargets,
    isCustomTarget,
    basicTargetIds,
    customTargetId,
    customTargetList,
    onChangeTarget,
    targetValid,
    targetContentRateValid,
    isCustomTargetTimeout
  };
};
// 番組タイプ
const useProgramType = () => {
  // const labels = ['レギュラー', 'スペシャル', '単発', '再放送', '番宣'];
  const programTypes = PROGRAM_TYPES.map(v => ({
    id: v.id,
    label: v.label
  }));
  const programTypesValue = ref<Array<ProgramType>>(['REGULAR', 'SPECIAL']); // model
  const programTypesValid = computed(() => {
    if (programTypesValue.value.length === 0) {
      return 'いずれか一つは選択してください。';
    }
    return '';
  }); // valid
  return { programTypes, programTypesValue, programTypesValid };
};
// 集計単位
const useAggregationUnit = () => {
  const aggregationUnits = AGGREGATION_UNITS.map(v => ({
    label: v.label,
    value: v.id
  }));
  const aggregationUnitValue = ref<AggregationUnit>('BROADCAST_TIMES'); // model
  return { aggregationUnits, aggregationUnitValue };
};
// データ区分
const useDataDivision = () => {
  const dataDivisions = DATA_DIVISIONS.map(v => ({
    label: v.label,
    id: v.id
  }));
  const dataDivisionsValue = ref<Array<DataDivision>>(['VIEWING_RATE']); // model
  const dataDivisionsValid = computed(() => {
    if (dataDivisionsValue.value.length === 0) {
      return 'いずれか一つは選択してください。';
    }
    return '';
  }); // valid
  return { dataDivisions, dataDivisionsValue, dataDivisionsValid };
};
// 15分未満の番組
const useMin15Types = () => {
  const min15TypeValue = ref<boolean>(true); // model
  return { min15TypeValue };
};
// 番組ジャンル
const useProgramGenre = () => {
  const programGenres = PROGRAM_GENRES;
  const init = programGenres.map(v => v.id); // 初期値全選択の場合
  const programGenresValue = ref<Array<ProgramGenre>>([]); // model
  const programGenresIsAllClearValue = ref<boolean>(true);
  const programGenresOnAllClear = v => {
    if (v) {
      programGenresValue.value = [];
    } else {
      programGenresValue.value = init;
    }
  };
  const programGenresOnSelect = () => {
    programGenresIsAllClearValue.value = false;
    if (programGenresValue.value.length === 0) {
      programGenresIsAllClearValue.value = true;
    }
  };

  return {
    programGenres,
    programGenresValue,
    programGenresIsAllClearValue,
    programGenresOnAllClear,
    programGenresOnSelect
  };
};
// 曜日
const useDay = () => {
  const dayTypes = DAY_OF_WEEKS.map(v => ({
    label: v.label,
    id: v.id
  }));
  const init = dayTypes.map(v => v.id);
  const dayTypesValue = ref<Array<DayOfWeek>>(init); // model
  const dayTypesValid = computed(() => {
    if (dayTypesValue.value.length === 0) {
      return 'いずれか一つは選択してください。';
    }
    return '';
  }); // valid
  return {
    dayTypes,
    dayTypesValue,
    dayTypesValid
  };
};
// 祝日
const useHoliday = () => {
  const holidayTypesValue = ref<boolean>(true); // model
  return {
    holidayTypesValue
  };
};
