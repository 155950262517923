<template>
  <Box class="loading-message" text-align="center">
    <ThreeDotsSvg class="loading" />
    <Typography size="s" color="primary" line-height="1.5" class="mt-6">
      {{ message }}
    </Typography>
    <Typography size="s" color="primary" line-height="1.5">
      {{ subMessage }}
    </Typography>
  </Box>
</template>

<script>
import { defineComponent } from 'vue';
import ThreeDotsSvg from '@/assets/images/three-dots.svg?inline';
import Box from '@/components/layout/Box.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'LoadingMessage',
  components: {
    ThreeDotsSvg,
    Box,
    Typography
  },
  props: {
    message: {
      type: String,
      default: 'データを検索中です'
    },
    subMessage: {
      type: String,
      default: '読み込み完了まで、最大1分程度かかる場合があります'
    }
  }
});
</script>

<style lang="scss">
.loading-message {
  .ui-typography.primary,
  .loading {
    color: var(--chart-blue-2);
  }
  .loading {
    height: 10px;
  }
}
</style>
