/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { ref } from 'vue';

export const useDataTable = (): any => {
  const textFilterMatchModes = ref([
    { label: '等しい', value: FilterMatchMode.EQUALS },
    { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
    { label: '含む', value: FilterMatchMode.CONTAINS },
    { label: '含まない', value: FilterMatchMode.NOT_CONTAINS }
  ]);
  const numberFilterMatchModes = ref([
    { label: '以上', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
    { label: '以下', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    { label: 'より大きい', value: FilterMatchMode.GREATER_THAN },
    { label: 'より小さい', value: FilterMatchMode.LESS_THAN }
  ]);
  const filters = ref({
    stationName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    programName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    programType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    programBroadcastMinutes: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO }
      ]
    }
  });

  return {
    textFilterMatchModes,
    numberFilterMatchModes,
    filters
  };
};
