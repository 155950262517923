import { AxiosResponse } from 'axios';
import { inject, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { differenceInDays, format, getHours, startOfDay } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { TvDataSearchApi } from '@/api';
import {
  AreaInfo,
  SampleSize,
  TvDataSearchProgramList,
  TvDataSearchProgramPeriodAverage
} from '@/api/openapi';
import { DATE_FORMAT } from '@/common/format';
import { toast } from '@/components/ui/Toast';
import {
  AGGREGATION_UNITS,
  AggregationUnit,
  DATA_DIVISIONS,
  DataDivision,
  DAY_OF_WEEKS,
  FormValue,
  PROGRAM_GENRES,
  PROGRAM_TYPES,
  ProgramGenre,
  useProgramListFormBox
} from '@/composables/datasearch/programlist/FormBox';
import { COMPANY_ROUTES } from '@/router';

interface useProgramListDataType {
  breadcrumbs: Ref<{
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  }>;
  isInitialDisplay: Ref<boolean>;
  isDataLoading: Ref<boolean>;
  isShowTable: Ref<boolean>;
  isCsvLoading: Ref<boolean>;

  // 検索条件データ
  formData: FormValue;
  aggregationUnit: Ref<AggregationUnit>;
  dataDivisions: Ref<Array<DataDivision>>;

  // 検索結果データ
  samples: Ref<Array<SampleSize>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  programList: Ref<Array<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  programPeriodAverageList: Ref<Array<any>>;
  viewingRateTargets: Ref<Array<{ key: string; value: string }>>;
  viewingRateTargetWidth: Ref<number>;
  contentRateTargets: Ref<Array<{ key: string; value: string }>>;
  contentRateTargetWidth: Ref<number>;

  // 検索条件テキスト
  dateRangeText: Ref<string>;
  areaText: Ref<string>;
  convertCreateReport: Ref<string>;

  // ハンドラー
  searchProgramListData: (form: FormValue) => Promise<void>;
  exportCsvFile: (dt: Ref) => Promise<void>;
}

export const useProgramListData = (): useProgramListDataType => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const $papa: any = inject('$papa');

  const route = useRoute();
  const { params } = route;
  const companyId = Number(params.companyId);
  const searchDate = ref();

  // ベースデータ
  const breadcrumbs = ref({
    parents: [
      { name: COMPANY_ROUTES.top, label: 'ホーム' },
      { name: '', label: 'TVデータサーチ' }
    ],
    current: { label: '番組リスト' }
  });
  const isInitialDisplay = ref(true);
  const isDataLoading = ref(false);
  const isShowTable = ref(false);
  const isCsvLoading = ref(false);

  // 検索条件データ
  // データ初期化のため、formBoxのインスタンスを使用
  const { form } = useProgramListFormBox();
  const formData: FormValue = form;
  const aggregationUnit: Ref<AggregationUnit> = ref('BROADCAST_TIMES');
  const dataDivisions: Ref<Array<DataDivision>> = ref([]);

  // 検索結果データ
  const samples: Ref<Array<SampleSize>> = ref([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const programList: Ref<Array<any>> = ref([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const programPeriodAverageList: Ref<Array<any>> = ref([]);
  const viewingRateTargets: Ref<Array<{ key: string; value: string }>> = ref(
    []
  );
  const viewingRateTargetWidth: Ref<number> = ref(0);
  const contentRateTargets: Ref<Array<{ key: string; value: string }>> = ref(
    []
  );
  const contentRateTargetWidth: Ref<number> = ref(0);

  // 検索条件出力用
  const dateRangeText: Ref<string> = ref('');
  const areaText: Ref<string> = ref('');
  const convertCreateReport: Ref<string> = ref('');

  // 検索用一時変数
  let programGenres: Array<ProgramGenre> = [];
  let targetIds: Array<number> = [];
  let response!: AxiosResponse<
    TvDataSearchProgramList | TvDataSearchProgramPeriodAverage
  >;

  /**
   * 検索条件データを内部のインスタンスに反映する。
   * @param form 検索条件データ
   */
  const reflectionFormData = (form: FormValue) => {
    formData.selectDate = ref(form.selectDate.value);
    formData.startTime = ref(form.startTime.value);
    formData.endTime = ref(form.endTime.value);
    formData.area = ref(form.area.value);
    formData.areaOptions = ref(form.areaOptions.value);
    formData.programTypes = ref(form.programTypes.value);
    formData.min15Type = ref(form.min15Type.value);
    formData.programSeriesIds = ref(form.programSeriesIds.value);
    formData.programNames = ref(form.programNames.value);
    formData.targetName = ref(form.targetName.value);
    formData.isCustomTarget = ref(form.isCustomTarget.value);
    formData.basicTargetIds = ref(form.basicTargetIds.value);
    formData.customTargetId = ref(form.customTargetId.value);
    formData.aggregationUnit = ref(form.aggregationUnit.value);
    formData.dataDivisions = ref(form.dataDivisions.value);
    formData.stations = ref(form.stations.value);
    formData.stationOptions = ref(form.stationOptions.value);
    formData.dayOfWeeks = ref(form.dayOfWeeks.value);
    formData.holidayType = ref(form.holidayType.value);
    formData.programGenres = ref(form.programGenres.value);
    formData.selectStationValues = ref(form.selectStationValues.value);
  };

  /**
   * 番組データを検索する。
   * @param form 検索条件データ
   */
  const searchProgramListData = async (form: FormValue) => {
    try {
      searchDate.value = new Date();

      isInitialDisplay.value = false;
      isShowTable.value = false;
      isDataLoading.value = true;

      // 検索条件の反映
      reflectionFormData(form);
      aggregationUnit.value = formData.aggregationUnit.value;
      dataDivisions.value = formData.dataDivisions.value;

      programGenres =
        formData.programGenres.value.length === 0
          ? PROGRAM_GENRES.map(value => value.id)
          : formData.programGenres.value;
      targetIds = formData.isCustomTarget.value
        ? Array.isArray(formData.customTargetId.value)
          ? formData.customTargetId.value
          : [formData.customTargetId.value]
        : formData.basicTargetIds.value;

      convertCreateReport.value = format(
        new Date(),
        'yyyy年MM月dd日(E) HH:mm',
        {
          locale: ja
        }
      );

      const fetchRequests: Array<Promise<void>> = [fetchSampleSize()];

      // 集計単位が「放送単位」の場合
      if (formData.aggregationUnit.value === 'BROADCAST_TIMES')
        fetchRequests.push(fetchProgramList());

      // 集計単位が「番組単位」の場合
      if (formData.aggregationUnit.value === 'PROGRAM_TIMES')
        fetchRequests.push(fetchProgramPeriodAverageList());

      // 各種データ取得リクエストを同期
      await Promise.all(fetchRequests);

      // ターゲット情報の設定
      if (response.data.baseData.length > 0) {
        if (formData.dataDivisions.value.includes('VIEWING_RATE')) {
          viewingRateTargets.value = Object.keys(
            response.data.viewingRateData[0]
          ).map(e => ({
            key: 'viewing_' + e,
            value: e
          }));
          viewingRateTargetWidth.value = viewingRateTargets.value.length * 150;
        }
        if (formData.dataDivisions.value.includes('CONTENT_RATE')) {
          contentRateTargets.value = Object.keys(
            response.data.contentRateData[0]
          ).map(e => ({
            key: 'content_' + e,
            value: e
          }));
          contentRateTargetWidth.value = contentRateTargets.value.length * 150;
        }
      }

      // 検索条件テキスト関連の反映
      dateRangeText.value = [
        format(formData.selectDate.value?.start as Date, 'yyyy年MM月dd日(E)', {
          locale: ja
        }),
        format(formData.selectDate.value?.end as Date, 'yyyy年MM月dd日(E)', {
          locale: ja
        })
      ].join('~');

      const areaOptions: Array<AreaInfo> = formData.areaOptions.value
        .map(v => v.areas)
        .flatMap(v => v);
      const area: AreaInfo | undefined = areaOptions.find(
        v => v.id === formData.area.value
      );
      areaText.value = area === undefined ? '' : area.name;

      isShowTable.value = true;
    } catch (e) {
      console.error(e);
      isInitialDisplay.value = true;
      convertCreateReport.value = '';
      toast({ title: '失敗', message: e.message, variant: 'error' });
    } finally {
      isDataLoading.value = false;
    }
  };

  /**
   * CSVファイルを出力する。
   * @param dt DataTableインスタンス
   */
  const exportCsvFile = async (dt: Ref) => {
    isCsvLoading.value = true;

    const csvConditions = createCsvConditions(
      formData,
      samples.value,
      areaText.value,
      searchDate.value
    );

    const csvHeader = createCsvHeader(
      formData,
      viewingRateTargets.value,
      contentRateTargets.value
    );

    const csvBody = createCsvBody(
      formData,
      viewingRateTargets.value,
      contentRateTargets.value,
      dt
    );

    const link = document.createElement('a');
    const fileName =
      formData.aggregationUnit.value === 'BROADCAST_TIMES'
        ? `TVAL_PGLIST_c${companyId}_${[
            format(formData.selectDate.value?.start as Date, 'yyyyMMdd'),
            format(formData.selectDate.value?.end as Date, 'yyyyMMdd')
          ].join('-')}.csv`
        : `TVAL_PGAVG_c${companyId}_${[
            format(formData.selectDate.value?.start as Date, 'yyyyMMdd'),
            format(formData.selectDate.value?.end as Date, 'yyyyMMdd')
          ].join('-')}.csv`;
    const blob = new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]),
        $papa.unparse({
          data: [...csvConditions.concat(csvHeader).concat(csvBody)]
        })
      ],
      {
        type: 'text/csv'
      }
    );

    link.setAttribute('download', fileName);
    link.setAttribute('href', window.webkitURL.createObjectURL(blob));
    link.click();

    isCsvLoading.value = false;
  };

  const fetchSampleSize = async () => {
    samples.value = (
      await TvDataSearchApi.getTvdataSearchSampleSize(
        formData.area.value,
        formData.isCustomTarget.value,
        companyId,
        format(formData.selectDate.value?.start as Date, DATE_FORMAT),
        format(formData.selectDate.value?.end as Date, DATE_FORMAT),
        targetIds
      )
    ).data;
  };

  const fetchProgramList = async () => {
    response = await TvDataSearchApi.getTvdataSearchProgramListSearch(
      formData.stations.value,
      format(formData.selectDate.value?.start as Date, DATE_FORMAT),
      format(formData.selectDate.value?.end as Date, DATE_FORMAT),
      Object.values(formData.startTime.value).join(':'),
      Object.values(formData.endTime.value).join(':'),
      formData.dayOfWeeks.value,
      programGenres,
      formData.programTypes.value.concat(['UNKNOWN']),
      formData.holidayType.value,
      !formData.min15Type.value,
      formData.area.value,
      formData.isCustomTarget.value,
      companyId,
      formData.dataDivisions.value,
      formData.programSeriesIds.value,
      targetIds
    );
    programList.value = generateTableData(
      response.data,
      formData.aggregationUnit.value,
      formData.dataDivisions.value
    );
  };

  const fetchProgramPeriodAverageList = async () => {
    response = await TvDataSearchApi.getTvdataSearchProgramPeriodAverageSearch(
      formData.stations.value,
      format(formData.selectDate.value?.start as Date, DATE_FORMAT),
      format(formData.selectDate.value?.end as Date, DATE_FORMAT),
      Object.values(formData.startTime.value).join(':'),
      Object.values(formData.endTime.value).join(':'),
      formData.dayOfWeeks.value,
      programGenres,
      formData.programTypes.value.concat(['UNKNOWN']),
      formData.holidayType.value,
      !formData.min15Type.value,
      formData.area.value,
      formData.isCustomTarget.value,
      companyId,
      formData.dataDivisions.value,
      formData.programSeriesIds.value,
      targetIds
    );
    programPeriodAverageList.value = generateTableData(
      response.data,
      formData.aggregationUnit.value,
      formData.dataDivisions.value
    );
  };

  return {
    breadcrumbs,
    isInitialDisplay,
    isDataLoading,
    isShowTable,
    isCsvLoading,
    formData,
    aggregationUnit,
    dataDivisions,
    samples,
    programList,
    programPeriodAverageList,
    viewingRateTargets,
    viewingRateTargetWidth,
    contentRateTargets,
    contentRateTargetWidth,
    dateRangeText,
    areaText,
    convertCreateReport,
    searchProgramListData,
    exportCsvFile
  };
};

/**
 * レスポンスデータから、結果テーブル用にデータを整形して生成する。
 * @param responseData レスポンスデータ
 * @param aggregationUnit 集計単位
 * @param dataDivisions データ区分
 */
const generateTableData = (
  responseData: TvDataSearchProgramList | TvDataSearchProgramPeriodAverage,
  aggregationUnit: AggregationUnit,
  dataDivisions: Array<DataDivision>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Array<any> => {
  const baseData =
    aggregationUnit === 'BROADCAST_TIMES'
      ? responseData.baseData.map(v => {
          v.dayOfWeekSort = DAY_OF_WEEKS.find(
            option => option.label === v.dayOfWeek
          )?.sort;
          return v;
        })
      : responseData.baseData.map(v => {
          // 番組タイプ内訳（画面用）
          v.numberOfBroadcastsByProgramTypeForDisplay = v.numberOfBroadcastsByProgramType
            .map(v => `${Object.keys(v)[0].charAt(0)}${Object.values(v)[0]}`)
            .join(' ');
          // 番組タイプ内訳（CSV用）
          v.numberOfBroadcastsByProgramTypeForCsv = v.numberOfBroadcastsByProgramType
            .map(e => `${Object.keys(e)[0]}: ${Object.values(e)[0]}`)
            .join();
          return v;
        });

  const convertedViewingRateData = responseData.viewingRateData.map(elm => {
    const keys = Object.keys(elm);
    const values = Object.values(elm);
    return values.reduce(
      (a, v, i) => ({ ...a, ['viewing_' + keys[i]]: Number(v) }),
      {}
    );
  });
  const convertedContentRateData = responseData.contentRateData.map(elm => {
    const keys = Object.keys(elm);
    const values = Object.values(elm);
    return values.reduce(
      (a, v, i) => ({ ...a, ['content_' + keys[i]]: Number(v) }),
      {}
    );
  });

  return baseData.map((v, i) => {
    let result = { ...v };
    if (dataDivisions.includes('VIEWING_RATE')) {
      result = Object.assign(result, {
        ...convertedViewingRateData[i]
      });
    }
    if (dataDivisions.includes('CONTENT_RATE')) {
      result = Object.assign(result, {
        ...convertedContentRateData[i]
      });
    }
    return result;
  });
};

/**
 * CSVの検索条件部分を作成する。
 * @param formData 検索条件データ
 * @param samples サンプルリスト
 * @param areaText エリアテキスト
 * @param searchDate 検索日時
 */
const createCsvConditions = (
  formData: FormValue,
  samples: Array<SampleSize>,
  areaText: string,
  searchDate: Date
): Array<Array<string>> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getMapToLabelFunction = (options: Array<any>) => {
    return (v: string): string => options.find(e => e.id === v)?.label;
  };

  const dateRange = [
    format(formData.selectDate.value?.start as Date, 'yyyy年MM月dd日(E)', {
      locale: ja
    }),
    format(formData.selectDate.value?.end as Date, 'yyyy年MM月dd日(E)', {
      locale: ja
    })
  ].join('~');

  const timeRange = [
    `${formData.startTime.value.HH}:${formData.startTime.value.mm}`,
    `${formData.endTime.value.HH}:${formData.endTime.value.mm}`
  ].join('〜');

  const sampleSize = samples
    .map(
      e => `"${[e.name, Math.round(e.sampleSize).toLocaleString()].join(' ')}"`
    )
    .join();

  const stations = formData.selectStationValues.value
    .map(getMapToLabelFunction(formData.stationOptions.value))
    .join();

  const programNames =
    formData.programNames.value.length === 0
      ? '指定なし'
      : formData.programNames.value.join();

  const programTypes = formData.programTypes.value
    .map(getMapToLabelFunction(PROGRAM_TYPES))
    .join();

  const min15Type = formData.min15Type.value
    ? '15分未満の番組を除外する'
    : '15分未満の番組を除外しない';

  const dayOfWeeks = formData.dayOfWeeks.value
    .map(getMapToLabelFunction(DAY_OF_WEEKS))
    .join('');

  const holidayType = formData.holidayType.value
    ? '祝日を含む'
    : '祝日を含まない';

  const programGenres =
    formData.programGenres.value.length === 0
      ? '指定なし'
      : formData.programGenres.value
          .map(getMapToLabelFunction(PROGRAM_GENRES))
          .join();

  const aggregationUnit = AGGREGATION_UNITS.find(
    e => e.id === formData.aggregationUnit.value
  )?.label;

  const dataDivisions = formData.dataDivisions.value
    .map(getMapToLabelFunction(DATA_DIVISIONS))
    .join();

  const useData = isConfirmed(
    searchDate,
    formData.selectDate.value?.end as Date
  )
    ? '確報'
    : '速報';

  const reportCreateDate = format(searchDate, 'yyyy年MM月dd日(E) HH:mm', {
    locale: ja
  });

  return [
    ['TVAL - 番組リスト'],
    ['期間:', dateRange],
    ['時間帯', timeRange],
    ['曜日:', `${dayOfWeeks}（${holidayType}）`],
    ['エリア:', areaText],
    ['期間内最新サンプルサイズ:', sampleSize],
    ['番組タイプ:', `${programTypes}（${min15Type}）`],
    ['放送局:', stations],
    ['番組名:', programNames],
    ['ジャンル:', programGenres],
    ['集計単位:', `${aggregationUnit}`],
    ['出力データ:', dataDivisions],
    ['視聴種別:', 'リアルタイム'],
    ['利用データ:', useData],
    ['単位:', '%'],
    ['レポート作成日時:', reportCreateDate],
    ['データ提供元:', 'Switch Media, Inc.'],
    []
  ];
};

/**
 * CSVのヘッダー部分を作成する。
 * @param formData 検索条件データ
 * @param viewingRateTargets 視聴率ターゲット定義
 * @param contentRateTargets 含有率ターゲット定義
 */
const createCsvHeader = (
  formData: FormValue,
  viewingRateTargets: Array<{ key: string; value: string }>,
  contentRateTargets: Array<{ key: string; value: string }>
): Array<Array<string>> => {
  const headers: Array<string> = [];

  if (formData.aggregationUnit.value === 'BROADCAST_TIMES') {
    headers.push(
      ...[
        '放送日',
        '曜日',
        '祝日',
        '開始時刻',
        '終了時刻',
        'エリア',
        '集計対象都道府県',
        '放送局',
        '放送系列',
        'ジャンル',
        '番組名',
        '番組タイプ',
        '放送分数'
      ]
    );
  }
  if (formData.aggregationUnit.value === 'PROGRAM_TIMES') {
    headers.push(
      ...[
        '曜日',
        '祝日日数',
        '通常開始時刻',
        '通常終了時刻',
        'エリア',
        '集計対象都道府県',
        '放送局',
        '放送系列',
        'ジャンル',
        '番組名',
        '本数',
        '番組タイプ（内訳）',
        '通常放送分数'
      ]
    );
  }

  if (formData.dataDivisions.value.includes('VIEWING_RATE')) {
    headers.push(...viewingRateTargets.map(v => `視聴率：${v.value}`));
  }

  if (formData.dataDivisions.value.includes('CONTENT_RATE')) {
    headers.push(...contentRateTargets.map(v => `含有率（個人）：${v.value}`));
  }

  return [headers];
};

/**
 * CSVのボディ（データ）部分を作成する。
 * @param formData 検索条件データ
 * @param viewingRateTargets 視聴率ターゲット定義
 * @param contentRateTargets 含有率ターゲット定義
 * @param dt DataTableインスタンス
 */
const createCsvBody = (
  formData: FormValue,
  viewingRateTargets: Array<{ key: string; value: string }>,
  contentRateTargets: Array<{ key: string; value: string }>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dt: any
): Array<Array<string>> => {
  let baseData = JSON.parse(JSON.stringify(dt.value));

  const filters = dt.filters;
  const sortField = dt.d_sortField;
  const sortOrder = dt.d_sortOrder;

  // フィルターの反映
  Object.keys(filters)
    .map(key => [
      filters[key].constraints[0].value,
      filters[key].constraints[0].matchMode,
      key
    ])
    .forEach(filterDefile => {
      const filterValue = filterDefile[0];
      const filterMatchMode = filterDefile[1];
      const filterKey = filterDefile[2];
      if (filterValue) {
        baseData = baseData.filter(val => {
          switch (filterMatchMode) {
            case 'equals':
              return val[filterKey].toUpperCase() === filterValue.toUpperCase();
            case 'notEquals':
              return val[filterKey].toUpperCase() !== filterValue.toUpperCase();
            case 'contains':
              return (
                val[filterKey]
                  .toUpperCase()
                  .indexOf(filterValue.toUpperCase()) !== -1
              );
            case 'notContains':
              return (
                val[filterKey]
                  .toUpperCase()
                  .indexOf(filterValue.toUpperCase()) === -1
              );
            case 'gte':
              return val[filterKey] >= filterValue;
            case 'lte':
              return val[filterKey] <= filterValue;
            case 'gt':
              return val[filterKey] > filterValue;
            case 'lt':
              return val[filterKey] < filterValue;
          }
        });
      }
    });

  // ソートの反映
  if (sortField) {
    if (
      sortField === 'date' ||
      sortField === 'dayOfWeek' ||
      sortField === 'startTime' ||
      sortField === 'endTime' ||
      sortField === 'stationName' ||
      sortField === 'programGenreName' ||
      sortField === 'programName' ||
      sortField === 'programType' ||
      sortField === 'dayOfWeeks' ||
      sortField === 'numberOfBroadcastsByProgramTypeForDisplay'
    ) {
      // 文字列のソート
      if (sortOrder === 1) {
        baseData.sort((a, b) =>
          a[sortField].toString().localeCompare(b[sortField])
        );
      } else {
        baseData.sort((a, b) =>
          b[sortField].toString().localeCompare(a[sortField])
        );
      }
    } else {
      // 数値のソート
      if (sortOrder === 1) {
        baseData.sort((a, b) => a[sortField] - b[sortField]);
      } else {
        baseData.sort((a, b) => b[sortField] - a[sortField]);
      }
    }
  }

  const convertHourMinute = (value: string): string => {
    const dividedValue = value.split(':');
    return dividedValue[0] + ':' + dividedValue[1];
  };

  if (formData.aggregationUnit.value === 'BROADCAST_TIMES') {
    return baseData.map(v => {
      const dataSet = [
        v.date,
        v.dayOfWeek,
        v.isHoliday ? '祝' : '',
        convertHourMinute(v.startTime),
        convertHourMinute(v.endTime),
        v.areaName,
        v.areaPrefectureNames,
        v.stationName,
        v.stationNetwork,
        v.programGenreName,
        v.programName,
        v.programType,
        v.programBroadcastMinutes
      ];
      if (formData.dataDivisions.value.includes('VIEWING_RATE')) {
        dataSet.push(...viewingRateTargets.map(e => v[e.key]));
      }
      if (formData.dataDivisions.value.includes('CONTENT_RATE')) {
        dataSet.push(...contentRateTargets.map(e => v[e.key]));
      }
      return dataSet;
    });
  }

  if (formData.aggregationUnit.value === 'PROGRAM_TIMES') {
    return baseData.map(v => {
      const dataSet = [
        v.dayOfWeeks,
        v.numberOfHoliday,
        convertHourMinute(v.startTime),
        convertHourMinute(v.endTime),
        v.areaName,
        v.areaPrefectureNames,
        v.stationName,
        v.stationNetwork,
        v.programGenreName,
        v.programName,
        v.numberOfBroadcasts,
        v.numberOfBroadcastsByProgramTypeForCsv,
        v.programBroadcastMinutes
      ];
      if (formData.dataDivisions.value.includes('VIEWING_RATE')) {
        dataSet.push(...viewingRateTargets.map(e => v[e.key]));
      }
      if (formData.dataDivisions.value.includes('CONTENT_RATE')) {
        dataSet.push(...contentRateTargets.map(e => v[e.key]));
      }
      return dataSet;
    });
  }

  return [];
};

/**
 * 確報の判断を行う。
 * 29時間制で判断し、検索日と検索期間終了日の差分が3日以上ある場合、確報とする。
 * @param searchDate 検索日時
 * @param endDate 検索期間終了日
 */
const isConfirmed = (searchDate: Date, endDate: Date): boolean => {
  const hour = getHours(searchDate);
  if (0 <= hour && hour < 5) {
    return differenceInDays(startOfDay(searchDate), startOfDay(endDate)) >= 4;
  }
  return differenceInDays(startOfDay(searchDate), startOfDay(endDate)) >= 3;
};
